import { CategoryAxis, ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import {
  BIG_NUMBER_ABBREVIATION,
  BLACK,
  BRIGHT_BLUE,
  CYAN,
  GRAY,
  PURPLE,
  WHITE,
  LIGHT_CYAN,
  DARK_CYAN,
  LIGHT_YELLOW,
  DARK_PURPLE,
  GOLDEN_YELLOW,
} from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import {
  columnSeriesConfiguration,
  configureCategoryAxis,
  configureDateAxis,
  configureValueAxis,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";
import { isNull } from "../../../utils/utilityFunctions";

interface Props {
  data: any;
  seriesList: Array<{ name: string; field: string; type: string }>;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  currency: string;
}

const BAR_COLORS = [CYAN, BRIGHT_BLUE, GRAY, PURPLE];

class MediaFmiAndSovChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }

  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, seriesList, theme, currency } = this.props;

    // function to ensure that forecast data and non-forecast data have contrasting colors for better UX
    const valueColorMapping: { [key: string]: string } = seriesList.reduce((acc, series) => {
      switch (series.name) {
        case "In Market Forecast":
          acc[series.name] = CYAN;
          break;
        case "In Market":
          acc[series.name] = DARK_CYAN;
          break;
        case "Near Market Forecast":
          acc[series.name] = PURPLE;
          break;
        case "Near Market":
          acc[series.name] = DARK_PURPLE;
          break;
        case "Future Market Forecast":
          acc[series.name] = LIGHT_YELLOW;
          break;
        case "Future Market":
          acc[series.name] = GOLDEN_YELLOW;
          break;
        case "SOV":
          acc[series.name] = LIGHT_CYAN;
          break;
        default:
          acc[series.name] = GRAY;
      }
      return acc;
    }, {} as { [key: string]: string });

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);

    this.chart.colors.list = BAR_COLORS.map((item) => color(item));

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 40;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.numberFormatter.numberFormat = "#.a";
    valueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${currency} ${text}`;
    });

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}%`;
    });
    lineValueAxis.min = 0;
    // lineValueAxis.max = 100;
    lineValueAxis.strictMinMax;

    valueAxis.syncWithAxis = lineValueAxis;

    seriesList?.forEach(({ name, field, type }, idx) => {
      const series = type == "bar" ? this.chart.series.push(new ColumnSeries()) : this.chart.series.push(new LineSeries());
      series.data = data;
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";

      // Assign color based on the name
      const seriesColor = valueColorMapping[name] || "#000000"; // Default to black if no mapping found
      series.stroke = color(seriesColor);
      series.fill = color(seriesColor);

      if (type == "bar" && series instanceof ColumnSeries) {
        columnSeriesConfiguration(series, {
          name: name,
          valueY: field,
        });
        series.stacked = true;
        series.yAxis = valueAxis;
        series.columns.template.tooltipText = "test";
        //@ts-ignore
        series.columns.template.adapter.add("tooltipText", this.tooltipConfig);
      } else if (series instanceof LineSeries) {
        lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
        });
        series.yAxis = lineValueAxis;
      }

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }

      if (type == "dot") {
        series.strokeWidth = 0;
      }
    });

    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
    });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dataValue = target?.tooltipDataItem?.dataContext as { date: string;[index: string]: string | number };

    let text = `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;

      if (!isNull(value)) {
        text += `[${item.stroke}]●[/] ${item.name}: ${this.props.currency} ${Number(value).toLocaleString()}${item.name == "SOV" && value ? "%" : ""
          }\n`;
      }
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default MediaFmiAndSovChart;
