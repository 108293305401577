import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { ThemeContext } from "../../../../context";
import { useQuery } from "@tanstack/react-query";
import { getMediaFmiAndSov } from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import MediaFmiAndSovChart from "../../../../components/Charts/MarketPerformanceSummary/MediaFmiAndSovChart";
import { currencies } from "../../../../constants/currenctConstants";

const MediaFmiAndSov = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["mediaFmiAndSovData", history.location.search],
    queryFn: getMediaFmiAndSov,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>Media FMI and SOV</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <u>In Market:</u>
                <br />
                <u>Near Market:</u>
                <br />
                <u>Future Market:</u>
                <br />
                <u>SOV (Share of Voice):</u>
                <p>
                  Share of voice is a measure of the market each model owns compared to the competitors. It is based on the investments made
                  in different media groupings as Cinema, Internet, OOH, Print, Radio and TV and considers the models in the same segment
                  and geography. The calculation uses Japanese Yen as currency.
                </p>
                <p>Source: BIG3</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <div className="chart-container">
        <MediaFmiAndSovChart
          data={data?.data ?? []}
          seriesList={data?.series ?? []}
          chartName="mediaFmiAndSovChart"
          theme={theme}
          isLoading={isFetching}
          currency={data?.currency ? currencies[data.currency].symbol : ""}
        />
      </div>
    </div>
  );
});

export default MediaFmiAndSov;
