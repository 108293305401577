import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { ThemeContext } from "../../../../context";
import { useQuery } from "@tanstack/react-query";
import {
  getQualifiedVisitsAndKbasSummary,
  getQualifiedVisitsAndKbasTable,
  getQualifiedVisitsAndKbasTrend,
  getSearchInterestSummary,
  getSearchInterestTable,
  getSearchInterestTrend,
} from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import MarketPerformanceSummaryLineChart from "../../../../components/Charts/MarketPerformanceSummary/MarketPerformanceSummaryLineChart";
import MarketPerformanceSummaryTable from "./MarketPerformanceSummaryTable";
import { GRADE_MIX_COLORS } from "../../../../constants";
import { getValueClass, plusSignNumbers } from "../../../../utils/utilityFunctions";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { ToggleSwitch } from "../../../../components/Toogle";
import { format } from "date-fns";

const QualifiedVisitsAndKbas = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [qualifiedVisitsActive, setQualifiedVisitsActive] = useState(false);

  const {
    data: qualifiedVisitsAndKbasSummaryData,
    isFetching: isFetchingQualifiedVisitsAndKbasSummary,
    refetch: refetchQualifiedVisitsAndKbasSummary,
  } = useQuery({
    queryKey: ["qualifiedVisitsAndKbasSummary", qualifiedVisitsActive, history.location.search],
    queryFn: () => getQualifiedVisitsAndKbasSummary(qualifiedVisitsActive),
    enabled: false,
  });

  const {
    data: qualifiedVisitsAndKbasTrendData,
    isFetching: isFetchingQualifiedVisitsAndKbasTrend,
    refetch: refetchQualifiedVisitsAndKbasTrend,
  } = useQuery({
    queryKey: ["qualifiedVisitsAndKbasTrend", qualifiedVisitsActive, history.location.search],
    queryFn: () => getQualifiedVisitsAndKbasTrend(qualifiedVisitsActive),
    enabled: false,
  });

  const {
    data: qualifiedVisitsAndKbasTableData,
    isFetching: isFetchingQualifiedVisitsAndKbasTable,
    refetch: refetchQualifiedVisitsAndKbasTable,
  } = useQuery({
    queryKey: ["qualifiedVisitsAndKbasTable", qualifiedVisitsActive, history.location.search],
    queryFn: () => getQualifiedVisitsAndKbasTable(qualifiedVisitsActive),
    enabled: false,
  });

  useEffect(() => {
    refetchQualifiedVisitsAndKbasSummary();
    refetchQualifiedVisitsAndKbasTrend();
    refetchQualifiedVisitsAndKbasTable();
  }, [history.location.search, qualifiedVisitsActive]);

  const seriesColors = useMemo(() => {
    if (qualifiedVisitsAndKbasTrendData?.series) {
      const res: any = {};
      qualifiedVisitsAndKbasTrendData?.series?.map((seriesItem: any, idx: number) => {
        res[seriesItem.name] = GRADE_MIX_COLORS[idx];
      });
      return res;
    }
    return {};
  }, [qualifiedVisitsAndKbasTrendData]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>{qualifiedVisitsActive ? "Qualified visits" : "Visits"} and KBAs</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <u>Website visits:</u>
                <p>Website visit happens every time the site has at least one page fully loaded.</p>
                <p>Source: Adobe Analytics</p>
                <br />
                <u>KBAs:</u>
                <p>
                  KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare,
                  Saved searches views, Compare tools views and Dealer search.
                </p>
                <p>Source: Adobe Analytics</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        {/* <ToggleSwitch
          inactiveToggleLabel="Visits"
          activeToggleLabel="Qualified Visits"
          active={qualifiedVisitsActive}
          handleToggleClick={() => setQualifiedVisitsActive((prevState) => !prevState)}
        /> */}
      </div>
      <div className="tile-content">
        <div className="summary qualified-visits-summary-table">
          <div className="column">
            <span>Conversion</span>
            <br />
            <span>(MoM)</span>
          </div>
          <div className="column">
            <span>
              {qualifiedVisitsAndKbasSummaryData?.mom_date
                ? `${format(new Date(qualifiedVisitsAndKbasSummaryData?.mom_date), "MMM")}: ${
                    qualifiedVisitsAndKbasSummaryData?.mom ? `${qualifiedVisitsAndKbasSummaryData?.mom}%` : "n/a"
                  }`
                : ""}
            </span>
            <br />
            <span>
              {qualifiedVisitsAndKbasSummaryData?.mom_yoy && (
                <span className={`${getValueClass(qualifiedVisitsAndKbasSummaryData?.mom_yoy)}`}>
                  {qualifiedVisitsAndKbasSummaryData?.mom_yoy ? `${plusSignNumbers(qualifiedVisitsAndKbasSummaryData?.mom_yoy)}ppt` : ""}
                </span>
              )}
            </span>
          </div>
          <div className="column">
            <span>
              {qualifiedVisitsAndKbasSummaryData?.current_date
                ? `${format(new Date(qualifiedVisitsAndKbasSummaryData?.current_date), "MMM")}: ${
                    qualifiedVisitsAndKbasSummaryData?.current ? `${qualifiedVisitsAndKbasSummaryData?.current}%` : "n/a"
                  }`
                : ""}
            </span>
            <br />
            <span>
              {qualifiedVisitsAndKbasSummaryData?.current_yoy && (
                <span className={`${getValueClass(qualifiedVisitsAndKbasSummaryData?.current_yoy)}`}>
                  {qualifiedVisitsAndKbasSummaryData?.current_yoy
                    ? `${plusSignNumbers(qualifiedVisitsAndKbasSummaryData?.current_yoy)}ppt`
                    : ""}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="legends">
        {qualifiedVisitsAndKbasTrendData?.series?.map((series: any) => (
          <div className="legend-item" key={series.name}>
            <div className="line" style={{ borderColor: seriesColors[series.name] }}></div>
            <div>{series.name}</div>
          </div>
        ))}
      </div>
      <div className="chart-table-container">
        <MarketPerformanceSummaryLineChart
          chartId="qualifiedVisitsAndKbasBrandAndModelChart"
          data={qualifiedVisitsAndKbasTrendData?.data ?? []}
          isLoading={isFetchingQualifiedVisitsAndKbasTrend}
          theme={theme}
          seriesList={qualifiedVisitsAndKbasTrendData?.series ?? []}
          seriesColors={seriesColors}
        />
        <MarketPerformanceSummaryTable
          data={qualifiedVisitsAndKbasTableData ?? []}
          columnHeaders={["", `${qualifiedVisitsActive ? "Qualified visits" : "Visits"}`, "YoY"]}
          columnDataKeys={[
            { key: "model", type: "string" },
            { key: "visits", type: "number" },
            { key: "visits_yoy", type: "percentage" },
          ]}
        />
      </div>
    </div>
  );
});

export default QualifiedVisitsAndKbas;
