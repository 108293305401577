import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FiltersLayout } from "../../Layouts";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMsg } from "../../AppMessages";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_MARKET_PERFORMANCE_SUMMARY_PARAMETERS,
  DEFAULT_OCE_PARAMETERS,
  DEFAULT_PARAMETER_VALUES,
  JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS,
} from "../../../constants";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FilterContext } from "../../../context";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { setParameters, setSpecificParameter } from "../../../actions";
import { MixpanelEvents, eventTracking } from "../../../utils/userTracking";
import { DefaultFilter, MultiSelectFilter } from "../common";
import { useQuery } from "@tanstack/react-query";
import { getJourneyExcellenceMarketPerformanceSummaryFilters } from "../../../api";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { MarketPerformanceSummaryModelFilter } from "../common/MarketPerformanceSummaryModelFilter";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}

const MarketPerformanceSummaryFilters = withRouter((props: RouteComponentProps & Props): JSX.Element => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    model: modelParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [modelFilterValue, setModelFilterValue] = useState(modelParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Market Performance Summary");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_MARKET_PERFORMANCE_SUMMARY_PARAMETERS));
      currentDefaultFiltersSet("Market Performance Summary");
    }
    return () => currentDefaultFiltersSet("");
  }, [defaultFiltersSet]);

  useEffect(() => {
    if (!dateRangeParamValue.length) dispatch(setParameters(DEFAULT_MARKET_PERFORMANCE_SUMMARY_PARAMETERS));
    currentDefaultFiltersSet("Market Performance Summary");
  }, [dateRangeParamValue]);

  const { data, refetch } = useQuery({
    queryKey: ["filtersData", history.location.search],
    queryFn: getJourneyExcellenceMarketPerformanceSummaryFilters,
    initialData: {},
    enabled: false,
  });

  const { brand: brands, market: markets, model: models } = data;

  useEffect(() => {
    if (markets && markets.length > 0) {
      if (!markets.includes(marketParamValue)) {
        dispatch(setSpecificParameter("market", markets[0]));
      }
    }
  }, [marketParamValue, markets]);

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  // Set model filter value
  useEffect(() => {
    if (models && modelParamValue) {
      if (modelParamValue == "Brand") {
        setModelFilterValue("Brand");
      } else {
        const selectedModelsArr = modelParamValue.split(",");
        const modelSourceValue =
          selectedModelsArr?.length === models.length && selectedModelsArr.sort() == models.sort()
            ? `${selectedModelsArr?.length} model selected`
            : selectedModelsArr.every((v: any) => models.includes(v))
            ? selectedModelsArr?.length > 1
              ? `${selectedModelsArr?.length} model selected`
              : modelParamValue
            : "Brand";

        setModelFilterValue(modelSourceValue);
      }
    }
  }, [models, modelParamValue]);

  useEffect(() => {
    if (modelFilterValue == "Brand") dispatch(setSpecificParameter("model", "Brand"));
  }, [modelFilterValue]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Journey Excellence",
        page: "Market Performance Summary",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_MARKET_PERFORMANCE_SUMMARY_PARAMETERS));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("model", modelParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, modelParamValue, modelFilterValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            onFilterClick={showFilterOptionsFn}
            handleFilterOptionClick={handleFilterOptionClick}
            presetDatesList={JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands ?? []}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Market*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={markets ?? []}
            filterName={"market"}
            filterLabel={"GEOGRAPHY"}
            filterValue={marketParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MarketPerformanceSummaryModelFilter
            modelList={models ?? []}
            filterValue={modelFilterValue}
            modelParamValue={modelParamValue}
            showFilterOptionsFn={showFilterOptionsFn}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default MarketPerformanceSummaryFilters;
