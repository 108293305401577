import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setPageName, setSpecificParameter } from "../../actions";
import {
  getAvailableMarkets,
  getDigitalLeadsBySource,
  getDigitalLeadsVariance,
  getDigitallyInfluencedSalesTrend,
  getDisCloseRate,
  getDisCloseRateVariance,
  getDisLastRefreshDate,
  getPrivateSalesBreakdown,
  getSalesAndLeadsTrend,
  getSalesBreakdownBySource,
  getSalesOrLeadsTrendVariance,
  getSalesVariance,
} from "../../api";
import "../../assets/styles/pages/purchaseFunnel.scss";
import { ErrorMsg, NoDataMsg } from "../../components/AppMessages";
import {
  CurrentComparisonTrendline,
  DigitalLeadsSourceChart,
  LeadsSalesTrendChart,
  PrivateSalesBreakdownChart,
} from "../../components/Charts/PurchaseFunnel";
import Collapsible from "../../components/Collapsible/Collapsible";
import { PurchaseFunnelReportFilters } from "../../components/Filters/NewPurchaseFunnelFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { ToggleSwitch } from "../../components/Toogle";
import { ThemeContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ChartTile, KeyIndicatorTile } from "./Components";
import VarianceByChannelTable from "./Components/VarianceByChannelTable";
import DisByCarModel from "./module/DisByCarModel";

const marketFlags: Record<string, string> = {
  China: "China",
  "United States": "USA",
  Canada: "Canada",
  Mexico: "Mexico",
  "United Kingdom": "UK",
  Brazil: "Brazil",
  Japan: "Japan",
};

export const PurchaseFunnelReport = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;
  const themeContext = useContext(ThemeContext);
  const params = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);
  const { market: marketParamValue } = params;

  const [showDigitalSalesCumulative, setShowDigitalSalesCumulative] = useState(false);
  const [showPrivateSalesCumulative, setShowPrivateSalesCumulative] = useState(false);
  const [showAdjustedCloseRate, setShowAdjustedCloseRate] = useState(false);
  const [showDigitallyInfluencedLeads, setShowDigitallyInfluencedLeads] = useState(true);
  const [showDigitallyInfluencedSales, setShowDigitallyInfluencedSales] = useState(true);

  // Usage tracking
  useEffect(
    () => eventTracking(MixpanelEvents.page_view, { dashboard: "Purchase Funnel", page: "Purchase Funnel Report" }),
    [location.pathname]
  );

  const { data: lastRefreshDate, refetch: refetchDisLastRefreshDate } = useQuery({
    queryKey: ["disLastRefreshDate", history.location.search],
    queryFn: getDisLastRefreshDate,
    enabled: false,
  });

  const { data: availableMarkets, refetch: refetchAvailableMarkets } = useQuery({
    queryKey: ["availableMarkets", history.location.search],
    queryFn: getAvailableMarkets,
    enabled: false,
    initialData: [],
  });

  const {
    data: digitallyInfluencedSalesData,
    isFetching: digitallyInfluencedSalesIsFetching,
    refetch: refetchDigitallyInfluencedSales,
  } = useQuery({
    queryKey: ["percDigitallyInfluencedSales", showDigitalSalesCumulative, history.location.search],
    queryFn: () => getDigitallyInfluencedSalesTrend(showDigitalSalesCumulative),
    enabled: false,
  });

  const {
    data: privateSalesBreakdownData,
    isFetching: privateSalesBreakdownIsFetching,
    refetch: refetchPrivateSalesBreakdown,
  } = useQuery({
    queryKey: ["privateSalesBreakdown", showPrivateSalesCumulative, history.location.search],
    queryFn: () => getPrivateSalesBreakdown(showPrivateSalesCumulative),
    enabled: false,
  });

  const {
    data: closeRateData,
    isFetching: closeRateIsFetching,
    refetch: refetchCloseRate,
  } = useQuery({
    queryKey: ["closeRateData", history.location.search],
    queryFn: getDisCloseRate,
    enabled: false,
  });

  const {
    data: salesAndLeadsTrend,
    isFetching: salesAndLeadsTrendIsFetching,
    refetch: refetchSalesAndLeadsTrend,
  } = useQuery({
    queryKey: ["salesAndLeadsTrendData", history.location.search],
    queryFn: getSalesAndLeadsTrend,
    enabled: false,
  });

  const {
    data: disCloseRateVariance,
    isFetching: disCloseRateVarianceIsFetching,
    refetch: refetchDisCloseRateVarianceTrend,
  } = useQuery({
    queryKey: ["disCloseRateVarianceData", history.location.search],
    queryFn: getDisCloseRateVariance,
    enabled: false,
  });

  const {
    data: digitalLeadsBySource,
    isFetching: digitalLeadsBySourceIsFetching,
    refetch: refetchDigitalLeadsBySource,
  } = useQuery({
    queryKey: ["digitalLeadsBySourceData", showDigitallyInfluencedLeads, history.location.search],
    queryFn: () => getDigitalLeadsBySource(showDigitallyInfluencedLeads),
    enabled: false,
  });

  const {
    data: salesBreakdownBySource,
    isFetching: salesBreakdownBySourcIsFetching,
    refetch: refetchSalesBreakdownBySource,
  } = useQuery({
    queryKey: ["salesBreakdownBySourcData", showDigitallyInfluencedSales, history.location.search],
    queryFn: () => getSalesBreakdownBySource(showDigitallyInfluencedSales),
    enabled: false,
  });

  const {
    data: salesTrendVariance,
    isFetching: salesTrendVarianceIsFetching,
    refetch: refetchSalesTrendVarianceTrend,
  } = useQuery({
    queryKey: ["salesTrendVarianceData", history.location.search],
    queryFn: () => getSalesOrLeadsTrendVariance(true),
    enabled: false,
  });

  const {
    data: leadsTrendVariance,
    isFetching: leadsTrendVarianceIsFetching,
    refetch: refetchLeadsTrendVarianceTrend,
  } = useQuery({
    queryKey: ["leadsTrendVarianceData", history.location.search],
    queryFn: () => getSalesOrLeadsTrendVariance(false),
    enabled: false,
  });

  const {
    data: digitalLeadsVariance,
    isFetching: digitalLeadsVarianceIsFetching,
    refetch: refetchDigitalLeadsVarianceTrend,
  } = useQuery({
    queryKey: ["digitalLeadsVarianceData", showDigitallyInfluencedLeads, history.location.search],
    queryFn: () => getDigitalLeadsVariance(showDigitallyInfluencedLeads),
    enabled: false,
  });

  const {
    data: salesVariance,
    isFetching: salesVarianceIsFetching,
    refetch: refetchSalesVarianceTrend,
  } = useQuery({
    queryKey: ["salesVarianceData", history.location.search],
    queryFn: getSalesVariance,
    enabled: false,
  });

  useEffect(() => {
    refetchDigitallyInfluencedSales();
  }, [history.location.search, showDigitalSalesCumulative]);

  useEffect(() => {
    refetchPrivateSalesBreakdown();
  }, [history.location.search, showPrivateSalesCumulative]);

  useEffect(() => {
    refetchDigitalLeadsBySource();
  }, [history.location.search, showDigitallyInfluencedLeads]);

  useEffect(() => {
    refetchSalesBreakdownBySource();
  }, [history.location.search, showDigitallyInfluencedSales]);

  useEffect(() => {
    refetchDisLastRefreshDate();
    refetchAvailableMarkets();
    refetchCloseRate();
    refetchSalesAndLeadsTrend();
    refetchDisCloseRateVarianceTrend();
    refetchSalesTrendVarianceTrend();
    refetchLeadsTrendVarianceTrend();
    refetchDigitalLeadsVarianceTrend();
    refetchSalesVarianceTrend();
  }, [history.location.search]);

  useEffect(() => {
    let pageName = "DIS reporting";
    pageName = `DIS reporting\n ${availableMarkets?.length ? `(${availableMarkets?.join(", ")})` : ""}`;
    dispatch(setPageName(pageName));
    if (marketParamValue === "China") {
      setShowDigitallyInfluencedLeads(false);
      setShowAdjustedCloseRate(false);
    }
  }, [marketParamValue, availableMarkets]);

  useEffect(() => {
    if (marketParamValue.includes("United Kingdom")) {
      setShowDigitallyInfluencedSales(true);
      setShowDigitallyInfluencedLeads(true);
    }
  }, [marketParamValue]);

  useEffect(() => {
    dispatch(setSpecificParameter("digitally_influenced_sales_cumulative", showDigitalSalesCumulative));
  }, [showDigitalSalesCumulative]);

  useEffect(() => {
    dispatch(setSpecificParameter("private_sales_breakdown_cumulative", showDigitalSalesCumulative));
  }, [showDigitalSalesCumulative]);

  useEffect(() => {
    dispatch(setSpecificParameter("digital_leads_by_source_digitally_influenced_leads", showDigitallyInfluencedLeads));
  }, [showDigitallyInfluencedLeads]);

  useEffect(() => {
    dispatch(setSpecificParameter("sales_breakdown_by_source_digitally_influenced_sales", showDigitallyInfluencedSales));
  }, [showDigitallyInfluencedSales]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<PurchaseFunnelReportFilters />}
        lastDataRefresh={lastRefreshDate?.last_refresh_date}
        showLastDataRefreshAlert={false}
        dataDocumentation="purchase_funnel"
        pageTitle={<PageTitle flag={marketFlags[marketParamValue]} dataDocumentation="purchase_funnel" />}
      >
        <div className="pf_container">
          <ErrorBoundary fallback={<ErrorMsg />}>
            <KeyIndicatorTile />
          </ErrorBoundary>
          {/*DIGITALLY INFLUENCED SALES AND PRIVATE SALES CHART ROW*/}
          <div className="pf_row">
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ChartTile
                title="% Digitally influenced sales"
                toggle={
                  <ToggleSwitch
                    activeToggleLabel={""}
                    inactiveToggleLabel={"Show cumulative numbers"}
                    active={!showDigitalSalesCumulative}
                    handleToggleClick={() => setShowDigitalSalesCumulative(!showDigitalSalesCumulative)}
                    toggleClassName={"purchase_funnel_toggle"}
                  />
                }
                chart={
                  digitallyInfluencedSalesData?.data?.length > 0 ? (
                    <ErrorBoundary fallback={<ErrorMsg />}>
                      <CurrentComparisonTrendline
                        data={digitallyInfluencedSalesData?.data ?? []}
                        seriesList={digitallyInfluencedSalesData?.series ?? []}
                        chartName={"percentage_digitally_influenced_sales"}
                        isLoading={digitallyInfluencedSalesIsFetching}
                        theme={themeContext.theme}
                      />
                    </ErrorBoundary>
                  ) : (
                    <NoDataMsg />
                  )
                }
              />
            </ErrorBoundary>

            <ErrorBoundary fallback={<ErrorMsg />}>
              <ChartTile
                title="Private sales breakdown"
                toggle={
                  <ToggleSwitch
                    activeToggleLabel={""}
                    inactiveToggleLabel={"Show cumulative numbers"}
                    active={!showPrivateSalesCumulative}
                    handleToggleClick={() => setShowPrivateSalesCumulative(!showPrivateSalesCumulative)}
                    toggleClassName={"purchase_funnel_toggle"}
                  />
                }
                chart={
                  privateSalesBreakdownData?.data?.length > 0 ? (
                    <ErrorBoundary fallback={<ErrorMsg />}>
                      <PrivateSalesBreakdownChart
                        data={privateSalesBreakdownData?.data ?? []}
                        seriesList={privateSalesBreakdownData?.series ?? []}
                        theme={themeContext.theme}
                        isLoading={privateSalesBreakdownIsFetching}
                        chartName={"privates_sales_breakdown"}
                      />
                    </ErrorBoundary>
                  ) : (
                    <NoDataMsg />
                  )
                }
              />
            </ErrorBoundary>
          </div>

          {/*DIGITALLY INFLUENCED CLOSE RATE AND SALES LEADS CHART ROW */}
          {marketParamValue != "All" && marketParamValue.split(",").length == 1 && (
            <>
              <div className="pf_row">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ChartTile
                    title="Digitally influenced close rate"
                    tooltipText="Digitally influenced sales / Digital leads"
                    chart={
                      closeRateData?.data?.length > 0 ? (
                        <ErrorBoundary fallback={<ErrorMsg />}>
                          <CurrentComparisonTrendline
                            data={closeRateData?.data ?? []}
                            seriesList={closeRateData?.series ?? []}
                            chartName={"digitally_influenced_close_rates"}
                            isLoading={closeRateIsFetching}
                            theme={themeContext.theme}
                          />
                        </ErrorBoundary>
                      ) : (
                        <NoDataMsg />
                      )
                    }
                  />
                </ErrorBoundary>

                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ChartTile
                    title="Sales and leads trend"
                    tooltipText="The data is normalised to enable a trend comparison of key metrics. The Y-axis ranges from 0-1, representing the minimum and maximum monthly value"
                    chart={
                      salesAndLeadsTrend?.data?.length > 0 ? (
                        <ErrorBoundary fallback={<ErrorMsg />}>
                          <LeadsSalesTrendChart
                            data={salesAndLeadsTrend?.data ?? []}
                            seriesList={salesAndLeadsTrend?.series ?? []}
                            chartName={"sales_leads_trend_chart"}
                            isLoading={salesAndLeadsTrendIsFetching}
                            theme={themeContext.theme}
                          />
                        </ErrorBoundary>
                      ) : (
                        <NoDataMsg />
                      )
                    }
                  />
                </ErrorBoundary>
              </div>

              <Collapsible title="Variance by channel" show={true}>
                <div className="pf_row">
                  <div>
                    <VarianceByChannelTable
                      id={"dis_close_rate_table"}
                      title="Digitally influenced close rate variance by channel"
                      tooltipText="Variance numbers are given by the latest month selected in the date range. MoM compares the numbers with the previous month in the same fiscal year and YoY compares the numbers with the same month in the last fiscal year."
                      data={disCloseRateVariance}
                    />
                  </div>

                  <div>
                    <VarianceByChannelTable
                      id={"sales_table"}
                      title="Sales trend variance by channel"
                      tooltipText="Variance numbers are given by the latest month selected in the date range. MoM compares the sales with the previous month in the same fiscal year and YoY compares the Sale with the same month in the last fiscal year."
                      data={salesTrendVariance}
                    />
                    <VarianceByChannelTable
                      id={"leads_table"}
                      title="Leads trend variance by channel"
                      tooltipText="Variance numbers are given by the latest month selected in the date range. MoM compares the leads with the previous month in the same fiscal year and YoY compares the leads with the same month in the last fiscal year."
                      data={leadsTrendVariance}
                      isPercentage={true}
                    />
                  </div>
                </div>
              </Collapsible>
            </>
          )}

          {/*DIGITAL LEADS BY SOURCE CHART ROW*/}
          {marketParamValue != "All" && marketParamValue.split(",").length == 1 && (
            <>
              <div className="pf_row">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ChartTile
                    title="Digital leads by source"
                    toggle={
                      marketParamValue != "United Kingdom" ? (
                        <ToggleSwitch
                          activeToggleLabel={"Display digitally influenced leads"}
                          inactiveToggleLabel={"Display all leads"}
                          active={showDigitallyInfluencedLeads}
                          handleToggleClick={() => setShowDigitallyInfluencedLeads((prevState) => !prevState)}
                          toggleClassName={"purchase_funnel_toggle"}
                        />
                      ) : undefined
                    }
                    chart={
                      digitalLeadsBySource?.data != 0 ? (
                        <ErrorBoundary fallback={<ErrorMsg />}>
                          <DigitalLeadsSourceChart
                            data={digitalLeadsBySource?.data || []}
                            seriesList={digitalLeadsBySource?.series || []}
                            chartName="digital_leads_source_chart"
                            isLoading={digitalLeadsBySourceIsFetching}
                            theme={themeContext.theme}
                          />
                        </ErrorBoundary>
                      ) : (
                        <NoDataMsg />
                      )
                    }
                  />
                </ErrorBoundary>

                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ChartTile
                    title="Sales breakdown by source"
                    toggle={
                      marketParamValue != "United Kingdom" ? (
                        <ToggleSwitch
                          activeToggleLabel={"Display digitally influenced sales"}
                          inactiveToggleLabel={"Display all sales"}
                          active={showDigitallyInfluencedSales}
                          handleToggleClick={() => setShowDigitallyInfluencedSales((prevState) => !prevState)}
                          toggleClassName={"purchase_funnel_toggle"}
                        />
                      ) : undefined
                    }
                    chart={
                      salesBreakdownBySource?.data != 0 ? (
                        <ErrorBoundary fallback={<ErrorMsg />}>
                          <DigitalLeadsSourceChart
                            data={salesBreakdownBySource?.data || []}
                            seriesList={salesBreakdownBySource?.series || []}
                            chartName="sales_breakdown_by_source_chart"
                            isLoading={salesBreakdownBySourcIsFetching}
                            theme={themeContext.theme}
                          />
                        </ErrorBoundary>
                      ) : (
                        <NoDataMsg />
                      )
                    }
                  />
                </ErrorBoundary>
              </div>

              <Collapsible title="Variance by channel" show={true}>
                <div className="pf_row">
                  <VarianceByChannelTable
                    id={"dis_leads_table"}
                    title="Digital leads variance by channel"
                    tooltipText="Variance numbers are given by the latest month selected in the date range. MoM compares the Digital leads with the previous month in the same fiscal year and YoY compares the Digital leads with the same month in the last fiscal year."
                    data={digitalLeadsVariance}
                    isPercentage={true}
                    showInfinity={true}
                  />
                  <VarianceByChannelTable
                    id={"sales_and_leads_table"}
                    title="Sales variance by channel"
                    tooltipText="Variance numbers are given by the latest month selected in the date range. MoM compares the Sales with the previous month in the same fiscal year and YoY compares the Sales with the same month in the last fiscal year."
                    data={salesVariance}
                    isPercentage={true}
                    showInfinity={true}
                  />
                </div>
              </Collapsible>
            </>
          )}

          {marketParamValue != "All" && marketParamValue.split(",").length == 1 && <DisByCarModel />}
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
