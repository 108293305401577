import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE } from "../../../constants";
import { GeographyStateData } from "../../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isNull } from "../../../utils/utilityFunctions";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  onShowFilterOptions: any;
  geographies: GeographyStateData;
  value: any;
  isCpoDashboard?: boolean;
}

type StateUpdater = { geoType: string; isChecked: boolean; checkboxValue: string };

type checkboxState = { value: string; checked: boolean; indeterminate: boolean; parent?: string; children?: Array<string> };

export const NewGeographyFilter = (props: Props) => {
  const { onShowFilterOptions, geographies, value, isCpoDashboard } = props;

  const dispatch = useDispatch();

  const { market: marketParamValue, region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  let allGeographies = useSelector((state: RootStateOrAny) => state.filters.filterOptions.geography) ?? geographies;

  const [activeGeoState, setActiveGeoState] = useState("Region");

  const [regions, setRegions] = useState<Array<checkboxState>>([]);
  const [subRegions, setSubRegions] = useState<Array<checkboxState>>([]);
  const [markets, setMarkets] = useState<Array<checkboxState>>([]);
  const [selectionValue, setSelectionValue] = useState("");

  if (allGeographies && allGeographies.length == 0) allGeographies = geographies;

  //Set regions and sub regions
  useEffect(() => {
    if (geographies?.length) {
      const regionList = geographies.map((geo) => ({
        value: geo.region,
        checked: geo.checked,
        indeterminate: geo.indeterminate,
        children: geo.markets,
      }));
      setRegions(regionList);

      //Set sub regions
      const selectedRegions = regionList
        .filter((region) => region.checked || region.indeterminate)
        .map((filteredRegion) => filteredRegion.value);

      setSubRegions(
        geographies
          .filter((geo) => selectedRegions.includes(geo.region))
          .flatMap((filteredGeo) => {
            if ("subGeo" in filteredGeo)
              return filteredGeo.subGeo.map((sub) => ({ ...sub, parent: filteredGeo.region, children: sub.markets }));
            return [];
          })
          .map(({ region, checked, indeterminate, parent, children }) => ({ value: region, checked, indeterminate, parent, children }))
      );

      // Set Markets
      const selectedMarkets = marketParamValue.split(",");
      setMarkets(
        geographies
          .filter((geo) => selectedRegions.includes(geo.region))
          ?.flatMap((filteredGeo) => {
            if ("markets" in filteredGeo) {
              const { has_sub_region, market_split, markets, checked, region } = filteredGeo;
              return has_sub_region && market_split
                ? market_split.flatMap(({ region: subRegion, markets: splitMarkets }) =>
                  splitMarkets.map((splitMarket) => ({
                    value: splitMarket,
                    checked: selectedMarkets.includes("All countries")
                      ? true
                      : regionParamValue === subRegion || selectedMarkets.includes(splitMarket),
                    indeterminate: false,
                    parent: subRegion,
                  }))
                )
                : markets?.map((market) => ({
                  value: market,
                  checked: checked || selectedMarkets.includes(market),
                  indeterminate: false,
                  parent: region,
                }));
            }

            return [];
          })
          .sort((a, b) => a.value.localeCompare(b.value))
      );
    } else {
      setRegions([]);
      setMarkets([]);
      setSubRegions([]);
    }
  }, [geographies, marketParamValue, regionParamValue]);

  const breadcrumbSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const breadcrumbValue = dataset?.value;
      setActiveGeoState(breadcrumbValue as string);
    },
    [setActiveGeoState]
  );
  const updateCurrentState = useCallback(
    ({ geoType, isChecked, checkboxValue }: StateUpdater) => {
      switch (geoType) {
        case "region":
          const allIsChecked = checkboxValue === ALL_OPTION;

          const [allState, ...otherRegionState] = regions.map((row) => {
            return allIsChecked || row.value === checkboxValue ? { ...row, checked: isChecked, indeterminate: false } : { ...row };
          });

          const checkedRegionsLength = otherRegionState.filter((otherRegion) => otherRegion.checked).length;
          const indeterminateRegionsLength = otherRegionState.filter((otherRegion) => otherRegion.indeterminate).length;

          setRegions([
            {
              value: allState.value,
              checked: checkedRegionsLength === otherRegionState.length,
              indeterminate: indeterminateRegionsLength > 0 || (checkedRegionsLength > 0 && checkedRegionsLength < otherRegionState.length),
            },
            ...otherRegionState,
          ]);
          break;
        case "sub_region":
          const updatedSubRegionState = subRegions.map((row) =>
            row.value === checkboxValue ? { ...row, checked: isChecked, indeterminate: false } : { ...row }
          );
          setSubRegions(updatedSubRegionState);
          break;

        case "market":
          const updatedMarketState = markets.map((row) => (row.value === checkboxValue ? { ...row, checked: isChecked } : { ...row }));
          setMarkets(updatedMarketState);
          break;

        default:
          break;
      }
    },
    [regions, subRegions, markets]
  );

  const updateParentState = useCallback(
    ({ geoType, isChecked, parentValue, checkboxValue }: StateUpdater & { parentValue: string }) => {
      const parentElement = document.querySelector(`[type='checkbox'][value='${parentValue}']`) as HTMLInputElement;

      switch (geoType) {
        case "market":
          const childrenArray: Array<string> =
            parentElement.dataset.children && !isNull(parentElement.dataset.children) ? JSON.parse(parentElement.dataset.children) : [];
          const numberOfCheckedChildren = childrenArray
            .map((child) => {
              if (child === checkboxValue) return isChecked;
              const childElement = document.querySelector(`[type='checkbox'][value='${child}']`) as HTMLInputElement;
              return childElement.checked;
            })
            .filter((checked: boolean) => checked).length;

          const grandParentValue = parentElement.dataset.parent;

          if (grandParentValue) {
            const updatedSubRegions = subRegions.map((subRegion) =>
              subRegion.value === parentValue
                ? {
                  ...subRegion,
                  checked: childrenArray.length === numberOfCheckedChildren,
                  indeterminate: numberOfCheckedChildren > 0 && numberOfCheckedChildren < childrenArray.length,
                }
                : { ...subRegion }
            );
            setSubRegions(updatedSubRegions);

            const grandParentSubRegions = updatedSubRegions.filter((subRegion) => {
              const subRegionElement = document.querySelector(`[type='checkbox'][value='${subRegion.value}']`) as HTMLInputElement;
              const myParentValue = subRegionElement.dataset.parent;

              return myParentValue === grandParentValue;
            });

            const numberOfCheckSubRegions = grandParentSubRegions.filter((sub) => sub.checked).length;
            const numberOfIndeterSubRegions = grandParentSubRegions.filter((sub) => sub.indeterminate).length;

            const [allState, ...otherRegionState] = regions.map((region) =>
              region.value === grandParentValue
                ? {
                  ...region,
                  checked: numberOfCheckSubRegions === grandParentSubRegions.length,
                  indeterminate:
                    numberOfIndeterSubRegions > 0 ||
                    (numberOfCheckSubRegions > 0 && numberOfCheckSubRegions < grandParentSubRegions.length),
                }
                : { ...region }
            );

            const otherRegionStateCheckedLength = otherRegionState.filter((other) => other.checked).length;
            const otherRegionStateIndeterminateLength = otherRegionState.filter((other) => other.indeterminate).length;

            setRegions([
              {
                value: allState.value,
                checked: otherRegionState.length === otherRegionStateCheckedLength,
                indeterminate:
                  otherRegionStateIndeterminateLength > 0 ||
                  (otherRegionStateCheckedLength > 0 && otherRegionStateCheckedLength < otherRegionState.length),
              },
              ...otherRegionState,
            ]);
          } else {
            const [noSubAll, ...noSubRest] = regions.map((region) =>
              region.value === parentValue
                ? {
                  ...region,
                  checked: numberOfCheckedChildren === childrenArray.length,
                  indeterminate: numberOfCheckedChildren > 0 && numberOfCheckedChildren < childrenArray.length,
                }
                : { ...region }
            );
            const otherRegionStateCheckedLength = noSubRest.filter((other) => other.checked).length;
            const otherRegionStateIndeterminateLength = noSubRest.filter((other) => other.indeterminate).length;

            setRegions([
              {
                value: noSubAll.value,
                checked: noSubRest.length === otherRegionStateCheckedLength,
                indeterminate:
                  otherRegionStateIndeterminateLength > 0 ||
                  (otherRegionStateCheckedLength > 0 && otherRegionStateCheckedLength < noSubRest.length),
              },
              ...noSubRest,
            ]);
          }

          break;

        case "sub_region":
          const numberOfCheckedSubRegions = subRegions
            .map((child) => {
              if (child.value === checkboxValue) return isChecked;

              return child.checked;
            })
            .filter((checked) => checked).length;

          const numberOfIndeterminateSubRegions = subRegions
            .map((child) => child.indeterminate)
            .filter((indeterminate) => indeterminate).length;

          const [allStateSub, ...otherRegionStateSub] = regions.map((region) =>
            region.value === parentValue
              ? {
                ...region,
                checked: subRegions.length === numberOfCheckedSubRegions,
                indeterminate:
                  numberOfIndeterminateSubRegions > 0 || (numberOfCheckedSubRegions > 0 && numberOfCheckedSubRegions < subRegions.length),
              }
              : { ...region }
          );
          const otherRegionStateCheckedLength2 = otherRegionStateSub.filter((other) => other.checked).length;
          const otherRegionStateIndeterminateLength2 = otherRegionStateSub.filter((other) => other.indeterminate).length;

          setRegions([
            {
              value: allStateSub.value,
              checked: otherRegionStateSub.length === otherRegionStateCheckedLength2,
              indeterminate:
                otherRegionStateIndeterminateLength2 > 0 ||
                (otherRegionStateCheckedLength2 > 0 && otherRegionStateCheckedLength2 < otherRegionStateSub.length),
            },
            ...otherRegionStateSub,
          ]);
          break;

        default:
          break;
      }
    },
    [subRegions, regions]
  );

  const updateChildState = useCallback(
    ({ childrenArray, isChecked, geoType, checkboxValue }: StateUpdater & { childrenArray: Array<string> }) => {
      const updatedMarkets = markets.map((market) =>
        childrenArray.includes(market.value) ? { ...market, checked: isChecked, indeterminate: false } : { ...market }
      );
      switch (geoType) {
        case "sub_region":
          setMarkets(updatedMarkets);
          break;

        case "region":
          if (checkboxValue === ALL_OPTION) {
            setSubRegions(subRegions.map((subRegion) => ({ ...subRegion, checked: isChecked, indeterminate: false })));
            setMarkets(markets.map((market) => ({ ...market, checked: isChecked, indeterminate: false })));
          } else {
            setMarkets(updatedMarkets);

            const subRegionElements = Array.from(
              document.querySelectorAll(`[type='checkbox'][data-parent='${checkboxValue}']`) as NodeListOf<HTMLInputElement>
            );

            if (subRegionElements.length) {
              const subRegionValues = subRegionElements.map((element) => element.value);
              const updatedSubRegionState = subRegions.map((subRegion) =>
                subRegionValues.includes(subRegion.value) ? { ...subRegion, checked: isChecked, indeterminate: false } : { ...subRegion }
              );

              setSubRegions(updatedSubRegionState);
            }
          }
          break;

        default:
          break;
      }
    },
    [markets, subRegions]
  );

  const updateAvailableLists = useCallback(
    ({ isChecked, geoType, checkboxValue }: StateUpdater) => {
      switch (geoType) {
        case "region":
          const selectedRegion = allGeographies.find((geo: { region: string; markets: Array<string> }) => geo.region === checkboxValue);

          const regionUpdatedMarkets = isChecked
            ? [
              ...markets.filter((market) => !selectedRegion.markets.includes(market.value)),
              ...selectedRegion.markets.map((market: string) => ({
                value: market,
                checked: isChecked,
                indeterminate: false,
                parent: selectedRegion.has_sub_region
                  ? selectedRegion.market_split.map((row: { markets: Array<string>; region: string }) => {
                    if (row.markets.includes(market)) return row.region;
                  })
                  : checkboxValue,
              })),
            ]
            : markets.filter((market) => !selectedRegion.markets.includes(market.value));

          if (selectedRegion.has_sub_region) {
            const subRegionsList = selectedRegion.market_split.map((split: { region: string }) => split.region);
            const regionUpdatedSubRegion = isChecked
              ? [
                ...subRegions.filter((subRegion) => !subRegionsList.includes(subRegion.value)),
                ...selectedRegion.market_split.map((split: { region: string; markets: Array<string> }) => ({
                  value: split.region,
                  checked: isChecked,
                  indeterminate: false,
                  children: split.markets,
                  parent: checkboxValue,
                })),
              ]
              : subRegions.filter((sub) => !subRegionsList.includes(sub.value));

            setSubRegions(regionUpdatedSubRegion.sort((a, b) => a.value.localeCompare(b.value)));
          }

          setMarkets(regionUpdatedMarkets.sort((a, b) => a.value.localeCompare(b.value)));
          break;

        case "sub_region":
          const currentElement = document.querySelector(`[type='checkbox'][value='${checkboxValue}']`) as HTMLInputElement;
          const childrenValues: Array<string> = JSON.parse(currentElement.dataset.children as string);

          const subUpdateMarket = isChecked
            ? [
              ...markets.filter((market) => !childrenValues.includes(market.value)),
              ...childrenValues.map((child) => ({ value: child, checked: isChecked, indeterminate: false, parent: checkboxValue })),
            ]
            : markets.filter((market) => !childrenValues.includes(market.value));

          setMarkets(subUpdateMarket.sort((a, b) => a.value.localeCompare(b.value)));
          break;

        default:
          break;
      }
    },
    [subRegions, markets, allGeographies]
  );

  const showAllOptions = useCallback(() => {
    const allMarkets: Array<{ value: string; checked: boolean; indeterminate: boolean; parent: string }> = [];
    const allSubRegions: Array<{ value: string; checked: boolean; indeterminate: boolean; children: Array<string>; parent: string }> = [];

    allGeographies.forEach(
      (geo: {
        region: string;
        markets: Array<string>;
        has_sub_region: boolean;
        market_split: Array<{ region: string; markets: Array<string> }>;
      }) => {
        const { region, has_sub_region, markets, market_split } = geo;
        if (region === ALL_OPTION) return;
        if (!has_sub_region) {
          allMarkets.push(...markets.map((market) => ({ value: market, checked: true, indeterminate: false, parent: region })));
        } else {
          market_split.forEach((split) => {
            allSubRegions.push({ value: split.region, children: split.markets, parent: region, checked: true, indeterminate: false });
            allMarkets.push(...split.markets.map((market) => ({ value: market, checked: true, indeterminate: false, parent: region })));
          });
        }
      }
    );

    setRegions((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true, indeterminate: false })));
    setSubRegions(allSubRegions.sort((a, b) => a.value.localeCompare(b.value)));
    setMarkets(
      allMarkets.sort((a, b) => a.value.localeCompare(b.value)).map((market) => ({ ...market, checked: true, indeterminate: false }))
    );
  }, [allGeographies, subRegions, markets]);

  const selectAllMarkets = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { checked: isChecked },
      } = evt;

      const parentValues = Array.from(new Set([...markets.map((market) => market.parent)]));

      const updatedMarkets = markets.map((market) => ({ ...market, checked: isChecked }));
      const updatedSubRegion = subRegions.length ? subRegions.map((subRegion) => ({ ...subRegion, checked: isChecked })) : [];
      const [allRegion, ...otherRegions] = regions.map((region) => ({
        ...region,
        checked:
          region.value !== ALL_OPTION &&
          (parentValues.includes(region.value) || (parentValues.includes("AMIO" || "NAE") && region.value === "AMIEO")) &&
          isChecked,
        indeterminate: false,
      }));
      const checkedRegionsLength = otherRegions.filter((other) => other.checked).length;
      const indeterminateRegionsLength = otherRegions.filter((other) => other.indeterminate).length;

      setMarkets(updatedMarkets);
      setSubRegions(updatedSubRegion);
      setRegions([
        {
          value: allRegion.value,
          checked: otherRegions.length === checkedRegionsLength,
          indeterminate: indeterminateRegionsLength > 0 || (checkedRegionsLength > 0 && checkedRegionsLength < otherRegions.length),
        },
        ...otherRegions,
      ]);
    },
    [markets, regions, subRegions]
  );

  const selectAllSubRegions = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { checked: isChecked },
      } = evt;

      const updatedSubRegions = subRegions.map((subRegion) => ({ ...subRegion, checked: isChecked, indeterminate: false }));
      const parentValues = Array.from(new Set([...subRegions.map((subRegion) => subRegion.parent)]));
      const childrenValues = subRegions.flatMap((subRegion) => subRegion.children);

      const updatedMarkets = markets.map((market) => ({
        ...market,
        checked: childrenValues.includes(market.value) ? isChecked : market.checked,
        indeterminate: false,
      }));
      const [allRegion, ...otherRegions] = regions.map((region) => ({
        ...region,
        checked: parentValues.includes(region.value) ? isChecked : region.checked,
        indeterminate: parentValues.includes(region.value) ? false : region.indeterminate,
      }));
      const checkedRegionsLength = otherRegions.filter((other) => other.checked).length;
      const indeterminateRegionsLength = otherRegions.filter((other) => other.indeterminate).length;

      setSubRegions(updatedSubRegions);
      setMarkets(updatedMarkets);
      setRegions([
        {
          value: allRegion.value,
          checked: otherRegions.length === checkedRegionsLength,
          indeterminate: indeterminateRegionsLength > 0 || (checkedRegionsLength > 0 && checkedRegionsLength < otherRegions.length),
        },
        ...otherRegions,
      ]);
    },
    [regions, subRegions, markets]
  );

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: checkboxValue, checked: isChecked, dataset },
      } = evt;

      // If All is deselected, clear all selections
      if (checkboxValue === ALL_OPTION) {
        if (!isChecked) {
          resetSelection();
        } else {
          showAllOptions();
        }
        return;
      }

      const geoType = dataset?.geographyType ? dataset?.geographyType : "";
      const parentValue = dataset?.parent ? dataset?.parent : "";
      const childrenArray = dataset.children ? JSON.parse(dataset.children) : [];

      Promise.all([
        // Update states
        updateParentState({ geoType, isChecked, parentValue, checkboxValue }),
        updateCurrentState({ geoType, isChecked, checkboxValue }),
        updateChildState({ geoType, isChecked, checkboxValue, childrenArray }),
      ]).then(() => {
        // Updates available list
        updateAvailableLists({ isChecked, geoType, checkboxValue });
      });
    },

    [regions, subRegions, markets]
  );

  const handleOnlyBtnClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget,
        currentTarget: { dataset },
      } = evt;

      const parentElement = currentTarget.parentElement as HTMLElement;
      const firstChild = parentElement.firstChild as HTMLInputElement;

      const geoType = firstChild.dataset.geographyType;
      const childrenValues: Array<string> = firstChild.dataset.children ? JSON.parse(firstChild.dataset.children) : [];
      const parentValue = firstChild.dataset.parent;
      const value = dataset.value;

      if (geoType === "region") {
        const selectedRegion = allGeographies.find((geo: { region: string; markets: Array<string> }) => geo.region === value);

        const updatedRegion = regions.map((region) => ({
          ...region,
          indeterminate: region.value === ALL_OPTION,
          checked: region.value === value,
        }));

        const regionUpdatedMarkets = selectedRegion.markets.map((market: string) => ({
          value: market,
          checked: true,
          indeterminate: false,
          parent: selectedRegion.has_sub_region
            ? selectedRegion.market_split.find((row: { markets: Array<string>; region: string }) => row.markets.includes(market)).region
            : value,
        }));

        if (selectedRegion.has_sub_region) {
          const regionUpdatedSubRegions = selectedRegion.market_split.map((split: { region: string; markets: Array<string> }) => ({
            value: split.region,
            checked: true,
            indeterminate: false,
            children: split.markets,
            parent: value,
          }));

          setSubRegions(regionUpdatedSubRegions);
        } else {
          setSubRegions([]);
        }

        setRegions(updatedRegion);
        setMarkets(regionUpdatedMarkets);
      }
      if (geoType === "sub_region") {
        const subUpdatedMarkets = childrenValues.map((child) => ({ value: child, checked: true, indeterminate: false, parent: value }));

        const updatedSubRegions = subRegions.map((sub) => ({ ...sub, checked: sub.value === value, indeterminate: false }));

        const updatedRegion = regions.map((region) => ({
          ...region,
          indeterminate: region.value === ALL_OPTION || region.value === parentValue,
          checked: false,
        }));

        setMarkets(subUpdatedMarkets);
        setSubRegions(updatedSubRegions);
        setRegions(updatedRegion);
      }
      if (geoType === "market") {
        const subUpdatedMarkets = markets.map((market) => ({ ...market, checked: market.value === value, indeterminate: false }));

        if (parentValue === "AMIO" || parentValue === "NAE") {
          const updatedSubRegion = subRegions.map((sub) => ({ ...sub, checked: false, indeterminate: sub.value === parentValue }));

          const updatedRegion = regions.map((region) => ({
            ...region,
            checked: false,
            indeterminate: region.value === ALL_OPTION || region.value === "AMIEO",
          }));

          setRegions(updatedRegion);
          setSubRegions(updatedSubRegion);
        } else {
          const updatedRegion = regions.map((region) => ({
            ...region,
            checked: false,
            indeterminate: region.value === ALL_OPTION || region.value === parentValue,
          }));

          const updatedSubRegion = subRegions.map((sub) => ({ ...sub, checked: false, indeterminate: false }));

          setRegions(updatedRegion);
          setSubRegions(updatedSubRegion);
        }

        setMarkets(subUpdatedMarkets);
      }
    },
    [dispatch, regions, subRegions, markets, allGeographies]
  );

  const resetSelection = useCallback(() => {
    const updatedRegions = regions.map((region) => ({ ...region, checked: false, indeterminate: false }));
    setMarkets([]);
    setSubRegions([]);
    setRegions(updatedRegions);
  }, [regions]);

  const handleSubmission = useCallback(
    (evt) => {
      evt.preventDefault();

      if (selectionValue === "") return;

      if (selectionValue.includes("market")) {
        const selectedMarkets = markets.filter((market) => market.checked);
        const value = selectedMarkets.map((market) => market.value).join(",");
        dispatch(setSpecificParameter("region", ALL_OPTION));
        dispatch(setSpecificParameter("market", value));
        eventTracking(MixpanelEvents.filter_change, { filter: "market", value, page: "OCE", dashboard: "OCE" });
      } else if (selectionValue.includes("sub")) {
        const selectedSubRegion = subRegions.filter((sub) => sub.checked);
        const value = selectedSubRegion.map((sub) => sub.value).join(",");
        dispatch(setSpecificParameter("region", value));
        dispatch(setSpecificParameter("market", ALL_COUNTRIES));
        eventTracking(MixpanelEvents.filter_change, { filter: "region", value, page: "OCE", dashboard: "OCE" });
      } else if (selectionValue.includes("region")) {
        const selectedRegions = regions.filter((region) => region.checked);
        const value = selectedRegions.map((region) => region.value).join(",");
        dispatch(setSpecificParameter("region", value));
        dispatch(setSpecificParameter("market", ALL_COUNTRIES));
        eventTracking(MixpanelEvents.filter_change, { filter: "region", value, page: "OCE", dashboard: "OCE" });
      } else {
        dispatch(setSpecificParameter("region", ALL_OPTION));
        dispatch(setSpecificParameter("market", ALL_COUNTRIES));
        eventTracking(MixpanelEvents.filter_change, { filter: "region", value: ALL_OPTION, page: "OCE", dashboard: "OCE" });
      }

      closeFilterOptions();
    },
    [markets, regions, subRegions, selectionValue]
  );

  // Updates selection value
  useEffect(() => {
    if (regions?.length) {
      const [allRegion, ...otherRegions] = regions;

      if (allRegion.checked) {
        setSelectionValue("All selected");
        return;
      }

      if (!allRegion.checked && !allRegion.indeterminate) {
        setSelectionValue("");
        return;
      }

      const checkedRegions = otherRegions.filter((region) => region.checked);
      const indeterminateRegions = otherRegions.filter((region) => region.indeterminate);
      const checkedRegionsNumber = checkedRegions.length;
      const indeterminateRegionsNumber = indeterminateRegions.length;

      if (checkedRegionsNumber && !indeterminateRegionsNumber) {
        setSelectionValue(`${checkedRegionsNumber} ${checkedRegionsNumber === 1 ? "region" : "regions"} selected`);
        return;
      }

      const checkedSubRegions = subRegions.filter((sub) => sub.checked);
      const indeterminateSubRegions = subRegions.filter((sub) => sub.indeterminate);
      if (!checkedRegionsNumber && indeterminateRegionsNumber === 1 && indeterminateRegions[0].value === "AMIEO") {
        if (!indeterminateSubRegions.length && checkedSubRegions.length) {
          setSelectionValue(`${checkedSubRegions.length} ${checkedSubRegions.length === 1 ? "sub region" : "sub regions"} selected`);
          return;
        }
      }

      const checkedMarkets = markets.filter((market) => market.checked);

      setSelectionValue(`${checkedMarkets.length} ${checkedMarkets.length === 1 ? "market" : "markets"} selected`);
    }
  }, [regions, subRegions, markets]);

  const renderList = (list: Array<checkboxState>, geographyType: string) =>
    list.map(({ value, checked, indeterminate, parent, children }) => (
      <div className={"multiselect_container geography_list"} key={value}>
        <input
          type="checkbox"
          className={`multiselect-checkbox ${geographyType}-checkbox geography_checkbox`}
          id={`${geographyType}_${value?.replace(" ", "_")}`}
          name={`${geographyType}_${value.replace(" ", "_")}`}
          value={value}
          data-parent={parent}
          data-children={children ? JSON.stringify(children) : ""}
          data-geography-type={geographyType}
          checked={checked}
          ref={(input) => {
            if (input) {
              input.indeterminate = indeterminate;
            }
          }}
          onChange={handleCheckboxClick}
        />
        <label className="multiselect-label geography_label" htmlFor={`${geographyType}_${value.replace(" ", "_")}`}>
          {value}
        </label>

        {!/all/gi.test(value) && (
          <Button
            onClick={handleOnlyBtnClick}
            className={"geographyOptionBtn"}
            size={"small"}
            variant={"outlined"}
            data-geography={geographyType}
            data-value={value}
          >
            ONLY
          </Button>
        )}
      </div>
    ));

  return (
    <div className="filter" id="geography_filter_div">
      <label className="filter_header" htmlFor={"region_country_filter"}>
        GEOGRAPHY
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={"geography_selector"}>
        <input readOnly type="text" id="region_country_filter" className={"filter_input"} data-test-id="region" value={value} />
        <span className="arrow_down " />
      </div>

      <div className="filter_options" id="geography_selector" data-test-id="geography_selector">
        <div className="search_div">
          <div className="selection_label hide_on_mobile">
            <p className={"selection_value"}>{selectionValue}</p>
          </div>

          <div className="search_clear_div">
            {/*Todo: Add search input in the next sprint*/}
            {/*<SearchInput />*/}
            <div className="clear_btn" onClick={resetSelection}>
              Clear all
            </div>
          </div>
        </div>
        <div className="breadcrumb_container hide_on_desktop">
          <Breadcrumb
            list={isCpoDashboard ? ["Region", "Countries"] : ["Region", "Sub region", "Countries"]}
            onBreadcrumbClick={breadcrumbSelection()}
            activeValue={activeGeoState}
          />
        </div>
        <div className="geography_options_title hide_on_mobile">
          <div className="geography_type">
            <p className="title">Regions</p>
          </div>
          <div className={`geography_type ${subRegions.length ? "" : "isHidden"}`}>
            <p className="title">Sub-regions</p>
          </div>
          <div className="geography_type">
            <p className="title">Countries</p>
          </div>
        </div>
        <div className="geography_options">
          {/* Regions */}
          <div className={`marketList ${activeGeoState === "Region" ? "active" : ""}`} id="region_list" data-test-id={"region_list"}>
            {renderList(regions, "region")}
          </div>

          {/* Sub regions */}
          <div
            className={`marketList ${activeGeoState === "Sub region" ? "active" : ""} ${subRegions.length ? "" : "isHidden"}`}
            id="sub_region_list"
            data-test-id={"sub_region_list"}
          >
            <div className={"multiselect_container geography_list"} key={value}>
              <input
                type="checkbox"
                className={`multiselect-checkbox geography_checkbox`}
                id={`sub_region_all`}
                name={`sub_region_all`}
                value={ALL_OPTION_NO_SPACE}
                checked={subRegions.filter((market) => market.checked).length === subRegions.length}
                ref={(input) => {
                  if (input) {
                    const checkedSubRegions = subRegions.filter((market) => market.checked);
                    input.indeterminate = checkedSubRegions.length > 0 && checkedSubRegions.length < subRegions.length;
                  }
                }}
                onChange={selectAllSubRegions}
              />
              <label className="multiselect-label geography_label" htmlFor={`sub_region_all`}>
                {ALL_OPTION_NO_SPACE}
              </label>
            </div>

            {renderList(subRegions, "sub_region")}
          </div>

          {/* Markets */}
          <div
            className={`marketList ${activeGeoState === "Countries" ? "active" : ""}`}
            id="countries_list"
            data-test-id={"countries_list"}
          >
            {markets.length ? (
              <>
                <div className={"multiselect_container geography_list"} key={value}>
                  <input
                    type="checkbox"
                    className={`multiselect-checkbox geography_checkbox`}
                    id={`market_all`}
                    name={`market_all`}
                    value={ALL_OPTION_NO_SPACE}
                    checked={markets.filter((market) => market.checked).length === markets.length}
                    ref={(input) => {
                      if (input) {
                        const checkedMarkets = markets.filter((market) => market.checked);
                        input.indeterminate = checkedMarkets.length > 0 && checkedMarkets.length < markets.length;
                      }
                    }}
                    onChange={selectAllMarkets}
                  />
                  <label className="multiselect-label geography_label" htmlFor={`market_all`}>
                    {ALL_OPTION_NO_SPACE}
                  </label>
                </div>
                {renderList(markets, "market")}
              </>
            ) : (
              <ul>
                <li className={"error_msg"} style={{ fontSize: "0.9rem" }}>
                  Please select a region to see a list of markets
                </li>
              </ul>
            )}
          </div>
        </div>
        {/* Apply btn */}
        <div className="geography_options__submit_area">
          <button className="filter_button no_icon" onClick={handleSubmission} data-test-id={"geography_apply_btn"}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
