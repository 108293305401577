import React, { useEffect } from "react";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import MarketPerformanceSummaryFilters from "../../../components/Filters/JourneyExcellence/MarketPerformanceSummaryFilters";
import { PageTitle } from "../../../components/PageContent/subs";
import { JOURNEY_EXCELLENCE_BTN_FILTERS } from "../../../constants/journeyExcellenceConstants";
import { useQuery } from "@tanstack/react-query";
import { getLastDataRefresh } from "../../../api/JourneyExcellenceMarketPerformanceSummary";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import "../../../assets/styles/pages/marketPerformanceSummary.scss";
import MediaFMIAndRetailSales from "./components/MediaFMIAndRetailSales";
import MediaFmiAndSov from "./components/MediaFmiAndSov";
import MediaFmiAndCoreModel from "./components/MediaFmiAndCoreModel";
import SearchInterestBrandAndModel from "./components/SearchInterestBrandAndModel";
import QualifiedVisitsAndKbas from "./components/QualifiedVisitsAndKbas";
import KbasAndLeads from "./components/KbasAndLeads";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const JourneyExcellenceMarketPerformanceSummary = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["marketPerformanceSumaryLastDataRefresh"],
    queryFn: getLastDataRefresh,
    enabled: false,
  });

  useEffect(() => {
    refetchLastDataRefresh();
  }, [history.location.search]);

  useEffect(() => {
    dispatch(setPageName("Market Performance Summary"));
  }, []);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "Market Performance Summary" });
  }, []);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketPerformanceSummaryFilters filterBtns={JOURNEY_EXCELLENCE_BTN_FILTERS} />}
        dataDocumentation="marketing_performance_summary"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="marketing_performance_summary" />}
      >
        <div className="market-performance-summary">
          <MediaFMIAndRetailSales />
          <MediaFmiAndSov />
          <MediaFmiAndCoreModel />
          <SearchInterestBrandAndModel />
          <QualifiedVisitsAndKbas />
          <KbasAndLeads />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default JourneyExcellenceMarketPerformanceSummary;
