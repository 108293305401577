import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setMidasMtpCategoryHideYrScnrRv, setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { ALL_OPTION_NO_SPACE, DEFAULT_MIDAS_PARAMETERS, MIDAS_BTN_FILTERS, MIDAS_DATE_FILTER_OPTIONS } from "../../constants";
import { FilterContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet, isNull } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, MultiSelectFilter } from "./common";
import { MidasGeoFilter } from "./common/MidasGeoFilter";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  isCustom?: boolean;
  hasFmiSort?: boolean;
  inComplete?: boolean;
  hasUnit?: boolean;
  hasModel?: boolean;
  hasPeriod?: boolean;
  hasYrScenarioFilter?: boolean;
  hasDateFilter?: boolean;
  hasMarketFilter?: boolean;
  noYearScenarioFilter?: boolean;
  noNscFilter?: boolean;
  hasYrScenarioToggle?: boolean;
  hasGranularity?: boolean;
  granularityOptions?: JSX.Element | null;
  showModelTotal?: boolean;
}

const MarketingInvastmentDashboardFilters = withRouter((props: RouteComponentProps & Props) => {
  const {
    hasGranularity,
    hasModel,
    filterBtns,
    hasDateFilter,
    hasMarketFilter,
    hasUnit,
    history,
    isCustom,
    hasFmiSort,
    inComplete,
    hasPeriod,
    noYearScenarioFilter,
    noNscFilter,
    hasYrScenarioToggle,
    granularityOptions,
    showModelTotal,
  } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const currentUrl = window.location.href;
  const pageName = document.getElementById("pageTitle")?.innerText;

  const filters: any = useSelector((state: RootStateOrAny) => state.filters.midas_filters);
  const hideYrScenario = useSelector((state: RootStateOrAny) => state.midas.midas_mtp_category_hide_yr_scnr_rv);

  const {
    date_range: dateParamValue,
    brand: brandParam,
    unit: unitParam,
    period: periodParam,
    yr_scnr_rv: yr_scnr_rvParam,
    local_currency_name: localCurrencyNameParam,
    fmi_sort: fmiSortParam,
    mdl_nm_rev: mdl_nm_revParam,
    model: modelParam,
    market: marketParam,
    mc_group: mcGroupParm,
    subgroup: subGroupParam,
    year_filter: yearFilterParam,
    granularity: granularityParam,
    show_touchpoints: showFmiParam,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [selectedYrSC, setSelectedYrSC] = useState<string[]>([]);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("MIDAS");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_MIDAS_PARAMETERS));
      currentDefaultFiltersSet("MIDAS");
    }
  }, []);

  useEffect(() => {
    if (!inComplete && (!filters?.market || isNull(!filters?.market))) dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE));
    if (!inComplete && !Object.keys(filters).length) {
      dispatch(setParameters(DEFAULT_MIDAS_PARAMETERS));
    }
  }, [dispatch]);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (marketParam?.includes("India") && hasMarketFilter) {
      if (filters.brand?.length > 0) {
        setInitialLoad(false);
      } else {
        setInitialLoad(true);
      }
    } else {
      setInitialLoad(true);
    }
  }, [marketParam, brandParam]);

  useEffect(() => {
    const pagePath = window.location.pathname;
    const pageVisited = MIDAS_BTN_FILTERS?.find((val: { navigateTo: string }) => val.navigateTo == pagePath);
    const pageName = pageVisited?.name;
    eventTracking(MixpanelEvents.page_view, { dashboard: "Midas", page: pageName });
  }, [window.location]);

  useEffect(() => {
    if (!inComplete) {
      if (Object.keys(filters).length) {
        if (marketParam?.includes("India") && hasMarketFilter) {
          if (initialLoad) {
            dispatch(setSpecificParameter("brand", ALL_OPTION_NO_SPACE));
          } else {
            if (filters?.brand?.length) {
              if (!filters?.brand?.includes(brandParam) && !brandParam?.includes("All")) {
                dispatch(setSpecificParameter("brand", filters?.brand[0]));
              }
            }
          }
        } else {
          if (filters?.brand?.length) {
            const selectedBrands = brandParam?.split(",");
            selectedBrands?.map((brand: any) => {
              if (!filters?.brand?.includes(brand)) dispatch(setSpecificParameter("brand", filters?.brand[0]));
            });
          }
        }

        if (!noYearScenarioFilter && filters?.yr_scnr_rv?.length && yr_scnr_rvParam) {
          if (yr_scnr_rvParam?.includes(",")) {
            const splitParam = yr_scnr_rvParam.split(",");
            const firstParam = splitParam[0];
            const secondParam = splitParam[1];
            if (!filters?.yr_scnr_rv?.includes(firstParam) || !filters?.yr_scnr_rv?.includes(secondParam)) {
              let yrParam = ""
              if (filters?.yr_scnr_rv[0] && filters?.yr_scnr_rv[1]) {
                yrParam = filters?.yr_scnr_rv[0] + "," + filters?.yr_scnr_rv[1];
              } else if (filters?.yr_scnr_rv[0]) {
                yrParam = filters?.yr_scnr_rv[0]
              } else if (filters?.yr_scnr_rv[1]) {
                yrParam = filters?.yr_scnr_rv[1]
              }
              dispatch(setSpecificParameter("yr_scnr_rv", yrParam));
            }
          }
        }

        if (
          hasModel &&
          filters?.model?.length &&
          !filters?.model?.includes(modelParam) &&
          !/all/gi.test(modelParam) &&
          !modelParam?.includes(",")
        )
          dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE));
        if (hasFmiSort && !filters?.fmi_sort?.includes(fmiSortParam)) dispatch(setSpecificParameter("fmi_sort", filters?.fmi_sort[0]));
        if (hasPeriod && !filters?.period?.includes(periodParam)) dispatch(setSpecificParameter("period", filters?.period[0]));
        if (hasDateFilter && filters?.year_filter?.length && !filters?.year_filter?.includes(yearFilterParam))
          dispatch(setSpecificParameter("year_filter", filters?.year_filter[0]));
      }
    }
  }, [
    inComplete,
    filters?.nsc,
    filters?.brand,
    filters?.yr_scnr_rv,
    filters?.mdl_nm_rev,
    filters?.fmi_sort,
    filters?.model,
    filters?.period,
    filters?.year_filter,
    filters?.market,
    hasGranularity,
    granularityParam,
    dateParamValue,
    history,
    brandParam,
    unitParam,
    hasPeriod,
    periodParam,
    hasFmiSort,
    fmiSortParam,
    mdl_nm_revParam,
    yr_scnr_rvParam,
    localCurrencyNameParam,
    hasModel,
    modelParam,
    marketParam,
    mcGroupParm,
    subGroupParam,
    yearFilterParam,
    noNscFilter,
    noYearScenarioFilter,
    hasMarketFilter,
    hasDateFilter,

    dispatch,
  ]);

  useEffect(() => {
    if (!inComplete) {
      dispatch(setSpecificParameter("local_currency_name", filters?.actual_local_currency_name));
    }
  }, [
    inComplete,
    filters?.nsc,
    filters?.brand,
    filters?.yr_scnr_rv,
    filters?.mdl_nm_rev,
    filters?.fmi_sort,
    filters?.model,
    filters?.period,
    filters?.year_filter,
    filters?.market,
    initialLoad,
    dateParamValue,
  ]);

  useEffect(() => {
    if (!inComplete) {
      const params = new URLSearchParams();
      params.set("brand", brandParam);
      params.set("mc_group", mcGroupParm);
      params.set("subgroup", subGroupParam);
      params.set("market", marketParam);
      params.set("local_currency_name", localCurrencyNameParam);
      if (!noYearScenarioFilter) params.set("yr_scnr_rv", yr_scnr_rvParam);
      params.set("unit", unitParam);
      if (hasFmiSort) params.set("fmi_sort", fmiSortParam);
      if (hasModel) params.set("model", modelParam);
      if (hasPeriod) params.set("period", periodParam);
      if (hasDateFilter) params.set("date_range", dateParamValue);
      if (hasMarketFilter) params.set("market", marketParam);
      if (hasGranularity) params.set("granularity", granularityParam);
      params.set("show_touchpoints", showFmiParam);
      history.push({ search: `?${params.toString()}` });
    }
  }, [
    inComplete,
    hasGranularity,
    granularityParam,
    dateParamValue,
    history,
    brandParam,
    unitParam,
    hasPeriod,
    periodParam,
    hasFmiSort,
    fmiSortParam,
    mdl_nm_revParam,
    yr_scnr_rvParam,
    localCurrencyNameParam,
    hasModel,
    modelParam,
    marketParam,
    subGroupParam,
    mcGroupParm,
    yearFilterParam,
    noNscFilter,
    noYearScenarioFilter,
    hasMarketFilter,
    hasDateFilter,
    showFmiParam,
  ]);

  useMemo(() => {
    setSelectedYrSC(yr_scnr_rvParam?.split(","));
  }, []);

  const sortedYrScNr = useMemo(() => {
    if (selectedYrSC?.length && filters?.yr_scnr_rv?.length) {
      return selectedYrSC?.sort((a, b) => filters?.yr_scnr_rv?.indexOf(a) - filters?.yr_scnr_rv?.indexOf(b));
    }
    return [];
  }, [filters, selectedYrSC]);

  const yrScenarioList = useMemo(() => {
    if (sortedYrScNr?.length) return [`Show ${sortedYrScNr[0]}`, `Hide ${sortedYrScNr[0]}`];
    return [];
  }, [filters?.yr_scnr_rv, sortedYrScNr]);

  const hideYrScenarioValue = useMemo(() => {
    if (sortedYrScNr?.length) return hideYrScenario ? `Hide ${sortedYrScNr[0]}` : `Show ${sortedYrScNr[0]}`;
    return "";
  }, [sortedYrScNr, yrScenarioList, hideYrScenario]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Midas", page: pageName });
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const handleFilterHideYrScnrRv = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setMidasMtpCategoryHideYrScnrRv(optionValue?.includes("Hide")));
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleBrandFilterSubmission = useCallback(() => {
    const parameterName = "BRAND";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();

    const value = selectedValues?.join(",");
    dispatch(setSpecificParameter("brand", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "brand", value, dashboard: "Midas" });
  }, []);

  const handleYRSCNRRVFilterSubmission = useCallback(() => {
    const parameterName = "YR_SCNR_RV";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();
    if (selectedValues.length > 2) selectedValues.shift();

    const value = selectedValues?.sort((a, b) => filters?.yr_scnr_rv?.indexOf(a) - filters?.yr_scnr_rv?.indexOf(b)).join(",");
    dispatch(setSpecificParameter("yr_scnr_rv", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "yr_scnr_rv", value, dashboard: "Midas", page: pageName });
  }, []);

  const handleMdlNmRevFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];

    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    closeFilterOptions();
    const value = filters?.model?.length === selectedValues?.length ? "All" : selectedValues.join(",");

    dispatch(setSpecificParameter("model", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "model", value, dashboard: "Midas" });
  }, [filters]);

  return (
    <FiltersLayout extraClass={`${isCustom ? "iframeFilters-midas" : "iframeFilters"}`} filterBtns={filterBtns}>
      <>
        {isCustom && !inComplete && (
          <div className="filte">
            {hasDateFilter && (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <PurchaseFunnelDateFilter
                  value={dateParamValue}
                  handleFilterOptionClick={handleFilterOptionClick}
                  onFilterClick={showFilterOptionsFn}
                  presetDatesList={MIDAS_DATE_FILTER_OPTIONS}
                  minDateValue="April 2019"
                />
              </ErrorBoundary>
            )}

            <ErrorBoundary fallback={<ErrorMsg />}>
              <MultiSelectFilter
                parentKey="brand"
                filterList={filters?.brand ? filters?.brand : []}
                filterName="BRAND"
                value={brandParam}
                parameterName={"BRAND"}
                parameterValue={brandParam}
                onShowFilterOptions={showFilterOptionsFn}
                handleFilterSubmission={handleBrandFilterSubmission}
                hasNoAll={true}
              />
            </ErrorBoundary>
            {noYearScenarioFilter ? null : (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MultiSelectFilter
                  parentKey="yr_scnr_rv"
                  filterList={filters?.yr_scnr_rv ? filters?.yr_scnr_rv : []}
                  filterName="YEAR SCENARIO"
                  value={yr_scnr_rvParam}
                  parameterName={"YR_SCNR_RV"}
                  parameterValue={yr_scnr_rvParam}
                  onShowFilterOptions={showFilterOptionsFn}
                  handleFilterSubmission={handleYRSCNRRVFilterSubmission}
                  hasNoAll={true}
                  maxSelectable={2}
                  selectedYrSC={selectedYrSC}
                  setSelectedYrSC={setSelectedYrSC}
                />
              </ErrorBoundary>
            )}

            <ErrorBoundary>
              <MidasGeoFilter availableRegions={filters?.market} onShowFilterOptions={showFilterOptionsFn} />
            </ErrorBoundary>

            {hasModel && (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MultiSelectFilter
                  parentKey="model"
                  filterList={filters?.model ? filters?.model : []}
                  filterName="MODEL"
                  value={modelParam}
                  parameterName={"model"}
                  parameterValue={modelParam}
                  onShowFilterOptions={showFilterOptionsFn}
                  handleFilterSubmission={handleMdlNmRevFilterSubmission}
                  showTotal={showModelTotal}
                />
              </ErrorBoundary>
            )}
            {(hasPeriod || hasGranularity) && (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <DefaultFilter
                  list={hasPeriod ? filters?.period : ["Month", "Quarter"]}
                  filterLabel={hasPeriod ? "PERIOD" : "GRANULARITY"}
                  filterName={hasPeriod ? "period" : "granularity"}
                  filterValue={hasPeriod ? periodParam : granularityParam}
                  handleFilterOptionClick={handleFilterOptionClick}
                />
              </ErrorBoundary>
            )}
            <ErrorBoundary fallback={<ErrorMsg />}>
              <DefaultFilter
                list={filters?.local_currency_name}
                filterLabel={"CURRENCY"}
                filterName="local_currency_name"
                filterValue={localCurrencyNameParam}
                handleFilterOptionClick={handleFilterOptionClick}
              />
            </ErrorBoundary>
            {hasUnit && (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <DefaultFilter
                  list={["K", "M", "B"]}
                  filterName={"unit"}
                  filterLabel={"UNIT"}
                  filterValue={unitParam}
                  handleFilterOptionClick={handleFilterOptionClick}
                />
              </ErrorBoundary>
            )}
            {hasYrScenarioToggle && (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <DefaultFilter
                  list={yrScenarioList}
                  filterName={"displayed_yr_scnr"}
                  filterLabel={"Displayed Year Scenarios"}
                  filterValue={hideYrScenarioValue}
                  handleFilterOptionClick={handleFilterHideYrScnrRv}
                />
              </ErrorBoundary>
            )}

            {granularityOptions}
          </div>
        )}
      </>
    </FiltersLayout>
  );
});

export default MarketingInvastmentDashboardFilters;
