import { captureException } from "@sentry/react";
import { AxiosError, AxiosResponse, CancelTokenSource, default as axios, default as defaultAxios, ResponseType } from "axios";
import Cookies from "js-cookie";
import { setCanAccessView } from "../actions";
import { __local__, __url__, __v2Url__ } from "../constants";
import { store } from "../store";

export const API_URL = __url__ || "https://dev-backend-dot-nisan-perf-dash-development.ew.r.appspot.com";
export const API_V2_URL = __v2Url__ || "https://api-v2-feat-2-dot-nisan-perf-dash-development.ew.r.appspot.com/";
export const API_V2_NEW_URL = __v2Url__ || "https://api-v2-feat-2-dot-nisan-perf-dash-development.ew.r.appspot.com/";

const token = Cookies.get("access_token");
export type defaultApiInt = Promise<Record<string, any>>;
export type anyApiInt = Promise<any>;

export const CancelRequestMessage = "Operation cancelled due to a new request";
export const CANCEL_TOKENS: Record<string, CancelTokenSource> = {};

//Seconds delay between function retries
// Re-fetch data after 4sec
export const secondsDelay = 4000 + Math.floor(Math.random() * 150) + 1;

export const apiParamsToObject = (params: string) => {
  const parameters = new URLSearchParams(params);
  const regionParameter = parameters.get("region");

  if (regionParameter === "ASEAN/Japan") return { ...Object.fromEntries(parameters.entries()), region: "ASEAN" };

  return { ...Object.fromEntries(parameters.entries()) };
};

export const simpleResponse = (response: AxiosResponse) => response;

export const handleResponse = (response: AxiosResponse) => response.data;

export const handleError = (response: AxiosError) => {
  const isCancelError = response.message === CancelRequestMessage;

  if (!isCancelError) {
    if (localStorage.getItem("expiredToken") == "true") {
      return;
    }

    const status = response.response?.status;

    const errorObj = {
      //@ts-ignore
      error: Object.prototype.hasOwnProperty.call(response.response?.data, "detail") ? response.response?.data?.detail : response.message,
      status: status,
    };

    // HACK: logout users if token has expired
    // TODO: implement refresh tokens
    if (errorObj.error == "expired_token") {
      const cookies = Cookies.get();
      for (const cookie in cookies) {
        Cookies.remove(cookie);
      }
      localStorage.setItem("expiredToken", "true");
      localStorage.removeItem("LAST_VISITED_DASHBOARD");
      location.reload();
    }

    if (status == 403) {
      store.dispatch(setCanAccessView(false));
    }

    captureException(errorObj);
    return errorObj;
  }
  return;
};

const V1Config = {
  baseURL: API_URL,
  headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
};

const V2Config = {
  baseURL: API_V2_URL,
  headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
};
const V2NewConfig = { baseURL: API_V2_NEW_URL, headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" } };

export const axiosNoBearer = defaultAxios.create(V1Config);

export const axiosPostV2 = defaultAxios.create({
  baseURL: API_V2_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
});

export const axiosWithBearer = defaultAxios.create({
  ...V1Config,
  headers: { ...V1Config.headers, Authorization: `Bearer ${token ? token : ""}` },
});

export const axiosNoBearerV2 = defaultAxios.create(V2Config);

export const axiosWithBearerV2 = defaultAxios.create({
  ...V2Config,
  headers: { ...V2Config.headers, Authorization: `Bearer ${token ? token : ""}` },
});

// V1
export const simpleFetch = (url: string) => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearer.get(url, { cancelToken: CANCEL_TOKENS[url].token }).then(simpleResponse).catch(handleError);
};

export const simpleFetchV2 = (url: string) => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearerV2.get(url, { cancelToken: CANCEL_TOKENS[url].token }).then(simpleResponse).catch(handleError);
};

export const fetchData = (url: string) => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearer.get(url, { cancelToken: CANCEL_TOKENS[url].token }).then(handleResponse).catch(handleError);
};

export const fetchDataNoToken = <T>(url: string, data: Record<string, any>): Promise<T> => {
  return __local__
    ? axiosNoBearer.get(url).then(handleResponse).catch(handleError)
    : axiosNoBearer.post(url, data).then(handleResponse).catch(handleError);
};

export const postData = <T>(url: string, data: Record<string, any>, tokenKey?: string): Promise<T> => {
  const tokenString = tokenKey ? tokenKey : url;

  if (tokenString in CANCEL_TOKENS) CANCEL_TOKENS[tokenString].cancel(CancelRequestMessage);

  CANCEL_TOKENS[tokenString] = axios.CancelToken.source();

  return axiosWithBearer
    .post(url, data, {
      cancelToken: CANCEL_TOKENS[tokenString].token,
      headers: { "Content-Type": data instanceof FormData ? "multipart/form-data" : "application/json" },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const postDataZipFile = <T>(url: string, data: Record<string, any>, tokenKey?: string): Promise<T> => {
  const tokenString = tokenKey ? tokenKey : url;

  if (tokenString in CANCEL_TOKENS) CANCEL_TOKENS[tokenString].cancel(CancelRequestMessage);

  CANCEL_TOKENS[tokenString] = axios.CancelToken.source();

  return axiosWithBearer
    .post(url, data, { cancelToken: CANCEL_TOKENS[tokenString].token, responseType: "arraybuffer" })
    .then(handleResponse)
    .catch(handleError);
};

export const postDataZipFileV2 = <T>(url: string, data: Record<string, any>, tokenKey?: string): Promise<T> => {
  const tokenString = tokenKey ? tokenKey : url;

  if (tokenString in CANCEL_TOKENS) CANCEL_TOKENS[tokenString].cancel(CancelRequestMessage);

  CANCEL_TOKENS[tokenString] = axios.CancelToken.source();

  return axiosWithBearerV2
    .post(url, data, { cancelToken: CANCEL_TOKENS[tokenString].token, responseType: "arraybuffer" })
    .then(handleResponse)
    .catch(handleError);
};

export const updateData = <T>(url: string, data: Record<string, any>): Promise<T> => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearer.put(url, data, { cancelToken: CANCEL_TOKENS[url].token }).then(handleResponse).catch(handleError);
};

export const deleteData = (url: string) => axiosNoBearer.delete(url).then(handleResponse).catch(handleError);

export const newApiFetch = <T, U>(url: string, data: Record<string, any>): Promise<U> => (__local__ ? fetchData(url) : postData(url, data));

// V2
export const fetchDataV2 = (url: string, headers?: Record<string, any>, responseType?: ResponseType): anyApiInt => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearerV2
    .get(url, { cancelToken: CANCEL_TOKENS[url].token, headers: headers, responseType: responseType })
    .then(handleResponse)
    .catch(handleError);
};

export const getDataNoTokenV2 = <T>(url: string, token = ""): Promise<T> =>
  !token
    ? axiosNoBearerV2.get(url).then(handleResponse).catch(handleError)
    : axiosNoBearerV2
      .get(url, { ...V2Config, headers: { ...V2Config.headers, Authorization: `Bearer ${token ? token : ""}` } })
      .then(handleResponse)
      .catch(handleError);

export const postDataNoTokenV2 = <T, U>(url: string, data: T, token = ""): Promise<U> =>
  !token
    ? axiosNoBearerV2.post(url, data).then(handleResponse).catch(handleError)
    : axiosNoBearerV2
      .post(url, data, { ...V2Config, headers: { ...V2Config.headers, Authorization: `Bearer ${token ? token : ""}` } })
      .then(handleResponse)
      .catch(handleError);

export const postDataV2 = (url: string, data: Record<string, any>, tokenKey?: string): anyApiInt => {
  const tokenString = tokenKey ? tokenKey : url;

  if (tokenString in CANCEL_TOKENS) CANCEL_TOKENS[tokenString].cancel(CancelRequestMessage);

  CANCEL_TOKENS[tokenString] = axios.CancelToken.source();

  return axiosWithBearerV2.post(url, data, { cancelToken: CANCEL_TOKENS[tokenString].token }).then(handleResponse).catch(handleError);
};

export const updateDataV2 = (url: string, data: Record<string, any>): anyApiInt => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearerV2.put(url, data, { cancelToken: CANCEL_TOKENS[url].token }).then(handleResponse).catch(handleError);
};

export const deleteDataV2 = (url: string): anyApiInt => axiosWithBearerV2.delete(url).then(handleResponse).catch(handleError);

export const newApiFetchV2 = <T>(url: string, data: Record<string, any>): anyApiInt =>
  __local__ ? fetchDataV2(url) : postDataV2(url, data);

//refactored endpoints
export const axiosNoBearerV2New = defaultAxios.create(V2Config);

export const axiosWithBearerV2New = defaultAxios.create({
  ...V2NewConfig,
  headers: { ...V2NewConfig.headers, Authorization: `Bearer ${token ? token : ""}` },
});
export const newFetchDataV2 = (url: string, headers?: Record<string, any>, responseType?: ResponseType): anyApiInt => {
  if (url in CANCEL_TOKENS) CANCEL_TOKENS[url].cancel(CancelRequestMessage);

  CANCEL_TOKENS[url] = axios.CancelToken.source();

  return axiosWithBearerV2New
    .get(url, { cancelToken: CANCEL_TOKENS[url].token, headers: headers, responseType: responseType })
    .then(handleResponse)
    .catch(handleError);
};

export const newPostDataV2 = (url: string, data: Record<string, any>, tokenKey?: string): anyApiInt => {
  const tokenString = tokenKey ? tokenKey : url;

  if (tokenString in CANCEL_TOKENS) CANCEL_TOKENS[tokenString].cancel(CancelRequestMessage);

  CANCEL_TOKENS[tokenString] = axios.CancelToken.source();

  return axiosWithBearerV2New.post(url, data, { cancelToken: CANCEL_TOKENS[tokenString].token }).then(handleResponse).catch(handleError);
};

export const apiFetchV2 = <T>(url: string, data: Record<string, any>): anyApiInt =>
  __local__ ? newFetchDataV2(url) : newPostDataV2(url, data);
