import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, percent, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BIG_NUMBER_ABBREVIATION, BLACK, BLUE, CCS_ANALYSIS_COLORS, MEDIA_MIX_CHART_COLORS, PURPLE, RED, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import {
  columnSeriesConfiguration,
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | null>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  currency: string;
}

const seriesColors = [RED, BLUE, PURPLE];

class MediaFmiAndRetailSalesChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, currency } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    this.chart.colors.list = MEDIA_MIX_CHART_COLORS.map((colorStr: string) => color(colorStr));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginBottom = 15;

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 30;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${currency} ${text}`;
    });
    valueAxis.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;

    const retailValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(retailValueAxis, theme);
    retailValueAxis.renderer.opposite = true;
    retailValueAxis.syncWithAxis = valueAxis;
    retailValueAxis.numberFormatter = new NumberFormatter();
    retailValueAxis.numberFormatter.numberFormat = "###,###";

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList
      .filter((series) => !series.field.includes("retail") && !series.field.includes("bp"))
      .forEach(({ name, field }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        if (seriesColors) series.fill = color(seriesColors[0]);
        if (seriesColors) series.stroke = color(seriesColors[0]);

        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: valueAxis,
        });
        series.numberFormatter = new NumberFormatter();
        series.numberFormatter.numberFormat = "###,###";
        circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM')}: [bold font-size: var(--regular_font_size)] ${currency} {valueY.numberFormat('#')}`;
        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
        }
        if (idx == 0) {
          series.strokeDasharray = "2,4";
        }
      });

    seriesList
      .filter((series) => series.field.includes("retail"))
      .forEach(({ name, field }, idx) => {
        const series = this.chart.series.push(new ColumnSeries());
        series.dataFields.dateX = "date";
        if (seriesColors) series.fill = color(seriesColors.at(-2));
        if (seriesColors) series.stroke = color(seriesColors.at(-2));

        columnSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: retailValueAxis,
        });
        series.numberFormatter = new NumberFormatter();
        series.numberFormatter.numberFormat = "###,###";

        series.columns.template.tooltipText = "{name}: {valueY.formatNumber('#,###.')}";
        series.columns.template.width = percent(65);
      });

    seriesList
      .filter((series) => series.field.includes("bp"))
      .forEach(({ name, field }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        if (seriesColors) series.fill = color(seriesColors.at(-1));
        if (seriesColors) series.stroke = color(seriesColors.at(-1));

        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: valueAxis,
        });
        series.numberFormatter = new NumberFormatter();
        series.numberFormatter.numberFormat = "###,###";
        circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM')}: [bold font-size: var(--regular_font_size)] {valueY.numberFormat('#')}`;
        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
        }
      });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default MediaFmiAndRetailSalesChart;
